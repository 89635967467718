import { Component, OnInit, Input } from '@angular/core';
// import { response } from 'express';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';



@Component({
  selector: 'app-footer-one',
  templateUrl: './footer-one.component.html',
  styleUrls: ['./footer-one.component.scss']
})
export class FooterOneComponent implements OnInit {

  @Input() class: string = 'footer-light' // Default class 
  // @Input() themeLogo: string = 'assets/images/icon/logo.png' // Default Logo
  @Input() themeLogo: string = 'assets/images/icon/logo-1-logo.png' // Default Logo
  @Input() newsletter: boolean = true; // Default True
  email: string = '';
  public today: number = Date.now();
  userappliedError:any

  private apibaseUrl = environment.apiUrl;


  constructor(private http: HttpClient,private modalService: NgbModal,private toastr: ToastrService) { }

  ngOnInit(): void {
  }

  subscribeFuc(){
    console.log('Email:', this.email);


   
       const currentDate = new Date().toISOString().slice(0, 19).replace('T', ' ');
   
       // console.log("this.imageFile", this.imageFile)
   
       const addFormData = {
        email:this.email,

        
       }
   
 
       console.log("formData===> what is here", addFormData);
   
       // Send form data to server
       this.http.post<any>(`${this.apibaseUrl}/addsubscriptionData`, addFormData)
         .pipe(
           tap((response: any) => {
             // Handle success response
             console.log('News added successfully:', response);
   
     
   
             // Close the modal after successful submission
             this.modalService.dismissAll();
   
                // Show a success toast
           this.toastr.success('Thank you for submitting your application for the position. We appreciate your interest and will be in contact with you soon.', 'Success!');
   
   
           }),
           catchError((error: HttpErrorResponse) => {
 
             console.log("error==",error)
 
             this.userappliedError = error
 
             console.log("this.userappliedError ",this.userappliedError)
 
             console.log("this.userappliedError.message.sqlMessage",this.userappliedError.error.message.sqlMessage)
 
 
 
              if(this.userappliedError.message.sqlMessage === "Duplicate entry '1' for key 'Job_application_table.unique_careers_id'"){
 
               // console.log("true")
             
               this.toastr.error('An error occurred while attempting to submit your application. Please try again.', 'Error!');
 
 
              }else{
 
               // console.log("false")
  
               this.toastr.error('You have already submitted an application for this position.', 'Error!');
              }
 
             // Handle error response
             console.error('Error adding news:', error);
             return throwError(error);
           })
         )
         .subscribe();
 
 
 
 
     // this.router.navigateByUrl('/pages/morevacancies',{ state: { news_ID: vacanciesItems.careers_id } })
 

 




  }

}
