<div class="row">
  <div class="col-md-4 service-block" *ngFor="let service of missionstatementData">
    <div class="media">

      <svg [attr.viewBox]="service.icon.viewBox">
        <path [attr.d]="service.icon.d" fill="#ff4c3b"></path>
      </svg>
      
      <div class="media-body">
        <h4>{{ service.title }}</h4>
        <p>{{ service.description }}</p>
      </div>
    </div>
  </div>
</div>
