import { Component, OnInit } from '@angular/core';
// 
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
// import { response } from 'express';
import { catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { baseUrl } from '../../../../config';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

  missionstatementData:any
  private apibaseUrl = environment.apiUrl;
  constructor(private http: HttpClient,) { }

  ngOnInit(): void {
    this.fetchmissionstatementData();
  }


  fetchmissionstatementData() {

    this.http.get<any>(`${this.apibaseUrl}/getmissionstatement`)
      .pipe(
        tap((response: any) => {

        }),
        catchError((error: HttpErrorResponse) => {

          console.log("error==>", error);
          console.error(error.error.message);
          return throwError(error);

        })
      )
      .subscribe((response: any) => {

        console.log("response", response)

        this.missionstatementData = response.results

        console.log("this.missionstatementData", this.missionstatementData)

      });
  }



}
