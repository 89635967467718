import { Component, OnInit, Input, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header-one',
  templateUrl: './header-one.component.html',
  styleUrls: ['./header-one.component.scss']
})
export class HeaderOneComponent implements OnInit {
  
  @Input() class: string;
  @Input() themeLogo: string = 'assets/images/icon/logo.png'; // Default Logo
  @Input() topbar: boolean = true; // Default True
  @Input() sticky: boolean = false; // Default false
  
  public stick: boolean = false;

  userIdData: any;

  LogoutData: boolean = false;
  LoginData: boolean = false;

  public isLoggedIn: boolean = false; // Add this property

  constructor(private router: Router) { }

  ngOnInit(): void {

    this.checkUserId();

  }

  // Method to check userIdData in localStorage

  checkUserId() {

    this.userIdData = localStorage.getItem('id');

    this.isLoggedIn = this.userIdData !== null && this.userIdData !== undefined;

    if (this.userIdData === null || this.userIdData === undefined) {
      console.log("Didn't login", this.userIdData)
      this.LoginData = true;
    } else {
      console.log("User Login", this.userIdData)
      this.LogoutData = true;    
    }

  }

  Logout() {
    this.userIdData =   localStorage.removeItem('id');

    this.isLoggedIn = false; // Update login status

    console.log("this.isLoggedIn",this.isLoggedIn)

    this.checkUserId(); // Check again after logout
    this.router.navigate(['/home']);
  }

  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (number >= 150 && window.innerWidth > 400) { 
      this.stick = true;
    } else {
      this.stick = false;
    }
  }
}
