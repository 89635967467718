import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trading-view-widget',
  templateUrl: './trading-view-widget.component.html',
  styleUrls: ['./trading-view-widget.component.scss']
})
export class TradingViewWidgetComponent implements OnInit {

  constructor() {
    this.loadScript();
   }

  ngOnInit(): void {

    console.log("trading-view-widget  ngOnInit")
    this.loadScript();

  }

  ngOnDestroy() {

    console.log("trading-view-widget  ngOnDestroy")
    // this.loadScript();
  }

  // ngAfterViewInit() {

  //    console.log("trading-view-widget  ngAfterViewInit")
  //   this.loadScript();
  // }

  // ngAfterContentInit(){

  //   console.log("trading-view-widget  ngAfterContentInit")
  //   this.loadScript();

  // }




  loadScript() {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js';
    script.async = true;
    script.innerHTML = JSON.stringify({
      symbols: [
        { proName: "FOREXCOM:SPXUSD", title: "S&P 500 Index" },
        { proName: "FOREXCOM:NSXUSD", title: "US 100 Cash CFD" },
        { proName: "FX_IDC:EURUSD", title: "EUR to USD" },
        { proName: "BITSTAMP:BTCUSD", title: "Bitcoin" },
        { proName: "BITSTAMP:ETHUSD", title: "Ethereum" },
        { description: "ZAR", proName: "OANDA:USDZAR" },
        { description: "gold", proName: "OANDA:XAUUSD" }
      ],
      showSymbolLogo: true,
      isTransparent: false,
      displayMode: "adaptive",
      colorTheme: "dark",
      locale: "en"
    });
    document.querySelector('.tradingview-widget-container')?.appendChild(script);
  }

}
