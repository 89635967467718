import { Component, OnInit, Input } from '@angular/core';
import { BlogSlider } from '../../../shared/data/slider';
// 
// 
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
// import { response } from 'express';
import { catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { baseUrl } from '../../../../config';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  
  @Input() blogs: any[] = [];

  newsData:any;
  baseUrl: string;
  private apibaseUrl = environment.apiUrl;
  constructor(private http: HttpClient,private router:Router) { }

  ngOnInit(): void {

    this.baseUrl = baseUrl
    this.fetchNewsData()
  }

  public BlogSliderConfig: any = BlogSlider;

  fetchNewsData() {

    this.http.get<any>(`${this.apibaseUrl}/getnewsData`)
      .pipe(
        tap((response: any) => {

        }),
        catchError((error: HttpErrorResponse) => {

          console.log("error==>", error);
          console.error(error.error.message);
          return throwError(error);

        })
      )
      .subscribe((response: any) => {

        console.log("response", response)

        this.newsData = response.results

        console.log("news_table", this.newsData)

      });
  }


  moreNews(newsItems){

    console.log("hello=>",newsItems);

    this.router.navigateByUrl('/pages/blog/details',{ state: { news_ID: newsItems.news_id } })
  }


}
