<header class="sticky" [ngClass]="class" [class.fixed]="stick && sticky">

  <div class="mobile-fix-option"></div>
  <div class="top-header" *ngIf="topbar" style="background: linear-gradient(to right, #ff0000, #ff0000, #ff5f5f,#f2804c,#ed3237,#ed3237,#ed3237);">
    <div class="container" >
      <div class="row" >
        <div class="col-lg-6">
          <div class="header-contact">
            <ul>
              <li style="color:aliceblue;">Welcome to KTVR bus service</li>
              <li style="color:aliceblue;"><i class="fa fa-phone" aria-hidden="true" style="color:aliceblue;"></i >Call Us: 011 823 1672</li>
            </ul>
          </div>
        </div>

        <div class="col-lg-6 text-end">

          <ul class="header-dropdown">
            <li class="compare">
              <!-- <a [routerLink]="['/shop/compare']"><i class="fa fa-random" aria-hidden="true"></i>Compare</a> -->
            </li>
          
            <li class="mobile-wishlist">
              <!-- <a [routerLink]="['/shop/wishlist']"><i class="fa fa-heart" aria-hidden="true"></i>Wishlist</a> -->
            </li>
          
            <li class="onhover-dropdown mobile-account" style="color:aliceblue;">
              <i class="fa fa-user" aria-hidden="true" style="color:aliceblue;"></i> My Account
              <ul class="onhover-show-div">
                <!-- Show login options if user is not logged in -->
                <ng-container *ngIf="!isLoggedIn">
                  <li>
                    <a data-lng="en" [routerLink]="['/pages/login']" skipLocationChange>
                      Log-in
                    </a>
                  </li>
                  <li>
                    <a data-lng="en" [routerLink]="['/pages/tenderlogin']" skipLocationChange>
                      tender
                    </a>
                  </li>
                </ng-container>
          
                <!-- Show logout option if user is logged in -->
                <ng-container *ngIf="isLoggedIn">
                  <li>
                    <a data-lng="es" [routerLink]="['/pages/dashboard']" skipLocationChange>
                      Dashboard
                    </a>
                  </li>
                  <li (click)="Logout()">
                    <a data-lng="es">
                      Logout
                    </a>
                  </li>
                </ng-container>
              </ul>
            </li>
          </ul>
          
          
        </div>


      </div>
    </div>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="main-menu">
          <div class="menu-left">

            <!-- <app-left-menu></app-left-menu> -->

            <div class="brand-logo">
              <a data-lng="en" [routerLink]="['/home/fashion']" skipLocationChange  id="headerlogo">
                <img [src]="themeLogo" class="img-fluid" alt="logo">
              </a>
            </div>

          </div>

          <div class="menu-right pull-right">

            <div>
              <app-menu></app-menu>
            </div>

            <div>
              <!-- <app-settings></app-settings> -->
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>

</header>
