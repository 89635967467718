<!--footer section -->
<footer [class]="class" >
	<div class="light-layout" *ngIf="newsletter" style="background: linear-gradient(to right, #ff0000, #ff0000, #eb1919,#d51c1c,#ed3237,#ed3237,#ed3237);">
		<div class="container" >
			<section class="small-section border-section border-top-0">
				<div class="row" >
					<div class="col-lg-6">
						<div class="subscribe">
							<div>
								<h4 style="color: aliceblue;">KNOW IT ALL FIRST!</h4>
								<p style="color: aliceblue;">Never Miss Anything From KTVR Bus Service By Signing Up To Our Newsletter. </p>
							</div>
						</div>
					</div>
					<div class="col-lg-6">
						<form class="form-inline subscribe-form" (ngSubmit)="subscribeFuc()" method="post">
							<div class="form-group mx-sm-3">
								<input type="email" [(ngModel)]="email" name="EMAIL" id="mce-EMAIL" class="form-control" placeholder="Enter your email">
							</div>
							<button type="submit" class="btn btn-solid">subscribe</button>
						</form>
						
					</div>
				</div>
			</section>
		</div>
	</div>
	<section class="section-b-space light-layout" style="background: linear-gradient(to right, #ff0000, #ff0000, #ff5f5f,#ed3237,#ee3114,#ed3237,#ed3237);">
		<div class="container" >
			<div class="row footer-theme partition-f">
				<div class="col-lg-4 col-md-6">
					<div class="footer-title footer-mobile-title">
						<h4>about</h4>
					</div>
					<div class="footer-contant">
						<div class="footer-logo">
							<img [src]="themeLogo" alt="logo">
						</div>
						<p style="color: aliceblue;">KTVR Bus Service (Pty) Ltd (“KTVR”) is a private company incorporated in terms of the Companies Act of 2008</p>
						<div class="footer-social">
                            <ul>
                                <li >
                                    <a href="https://www.facebook.com/profile.php?id=100083542533389"><i class="fa fa-facebook" aria-hidden="true" style="color: aliceblue;"></i></a>
                                </li>
                               
                                <li>
                                    <a href="https://twitter.com/KTVRbus_Service"><i class="fa fa-twitter" aria-hidden="true" style="color: aliceblue;"></i></a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/ktvrbus/"><i class="fa fa-instagram" aria-hidden="true" style="color: aliceblue;"></i></a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/company/ktvr-bus-services/?originalSubdomain=za"><i class="fa fa-linkedin" aria-hidden="true" style="color: aliceblue;"></i></a>
                                </li>
								<li>
                                    <a href="https://www.tiktok.com/@ktvrbusservice"><i class="fa fa-tiktok" aria-hidden="true" style="color: aliceblue;"></i></a>
                                </li>
                            </ul>
                        </div>

					
						

					</div>
				</div>
				<div class="col offset-xl-1">
					<div class="sub-title">
						<div class="footer-title">
                            <h4 style="color: aliceblue;">Navigation</h4>
                        </div>
                        <div class="footer-contant">
                            <ul style="color: aliceblue;">
                                <!-- <li ><a href="/home/fashion" style="color: white;">HOME</a></li> -->

								<li ><a data-lng="en" [routerLink]="['/home/fashion']" skipLocationChange style="color: white;">HOME</a></li>

								<li ><a data-lng="en" [routerLink]="['/pages/aboutus']" skipLocationChange style="color: white;">ABOUT US</a></li>

								<li ><a data-lng="en" [routerLink]="['/pages/blog/no/sidebar']" skipLocationChange style="color: white;">PUBLICATIONS</a></li>

								<li ><a data-lng="en" [routerLink]="['/pages/blog/left/sidebar']" skipLocationChange style="color: white;">NEWS</a></li>

								<li ><a data-lng="en" [routerLink]="['/pages/tender']" skipLocationChange style="color: white;">SUPPLY CHAIN</a></li>

								<li ><a data-lng="en" [routerLink]="['/pages/Vacancies']" skipLocationChange style="color: white;">VACANCIES</a></li>

								<li ><a data-lng="en" [routerLink]="['/pages/contact']" skipLocationChange style="color: white;">CONTACT US</a></li>
								<!-- <li ><a data-lng="en" [routerLink]="[/pages/contact']" skipLocationChange style="color: white;">CONTACT US</a></li> -->
								
                                <!-- <li ><a href="/pages/aboutus" style="color: white;">ABOUT US</a></li>
                                <li><a href="/pages/blog/no/sidebar" style="color: white;">PUBLICATIONS</a></li>
								<li><a href="pages/blog/left/sidebar" style="color: white;">NEWS</a></li>
								<li><a href="/pages/tender" style="color: white;">SUPPLY CHAIN</a></li>
								<li><a href="/pages/Vacancies" style="color: white;">VACANCIES</a></li>
								<li><a href="/pages/contact" style="color: white;">CONTACT US</a></li> -->

                                <!-- <li><a href="javascript:void(0)">EVENTS</a></li> -->
                            </ul>
                        </div>
					</div>
				</div>

				<!-- <div class="col">
					<div class="sub-title">
						<div class="footer-title">
                            <h4>why we choose</h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <li><a href="javascript:void(0)">shipping & return</a></li>
                                <li><a href="javascript:void(0)">secure shopping</a></li>
                                <li><a href="javascript:void(0)">gallary</a></li>
                                <li><a href="javascript:void(0)">affiliates</a></li>
                                <li><a href="javascript:void(0)">contacts</a></li>
                            </ul>
                        </div>
					</div>
				</div> -->
				
				<div class="col">
					<div class="sub-title">
						<div class="footer-title">
						    <h4 style="color: aliceblue;">Contact Us</h4>
						</div>
						<div class="footer-contant">
						    <ul class="contact-list">
						        <li style="color: aliceblue;"><i class="fa fa-map-marker"></i>KTVR Bus Service,03 Margaret Avenue,Kempton-Park,Gauteng, South Africa</li>
						        <li style="color: aliceblue;"><i class="fa fa-phone"></i>Call Us: 011 823 1672</li>
						        <li style="color: aliceblue;"><i class="fa fa-envelope-o"></i>Email Us: <a style="color: aliceblue;">info&#64;ktvr.co.za</a></li>
						        <li style="color: aliceblue;"><i class="fa fa-fax"></i>Fax: 086 498 6922</li>
						    </ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>

	<div class="sub-footer">
		<div class="container">
			<div class="row">
                <div class="col-xl-6 col-md-6 col-sm-12">
                  <div class="footer-end">
                    <p style="color: black;"> All Rights Reserved © {{ today | date:'y'}} KTVR BUS SERVICE</p>
                  </div>
                </div>
                <div class="col-xl-6 col-md-6 col-sm-12">
                  <div class="payment-card-bottom">
					<p style="color: black;">Website Created By <a href="https://linka.co.za/">Linka Technology </a>
					</p>
                  </div>
                </div>
            </div>
		</div>
	</div>
</footer>
<!--footer section end -->