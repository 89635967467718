<app-header-one [sticky]="true"></app-header-one>



<!--  -->

<div >

  <app-trading-view-widget></app-trading-view-widget>

  <!-- <div class="tradingview-widget-container" style="margin-top: -15px;">
    <div class="tradingview-widget-container__widget"></div>
    <div class="tradingview-widget-copyright">
      <a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank">
    
      </a>
    </div>
  </div> -->

  <owl-carousel-o [options]="customOptions" >
    <ng-container *ngFor="let slide of sliders">
      <ng-template carouselSlide [id]="slide.id">
        <img [src]="baseUrl+slide.image" [alt]="slide.alt" [title]="slide.title">
      </ng-template>
    </ng-container>
  </owl-carousel-o>

</div>



<!-- services start-->

<div class="container">
  <section class="service border-section small-section">
    <app-services></app-services>
  </section>
</div>

<!-- collection banner End -->

<!-- Product slider start-->

<div class="title1 section-t-space" *ngFor="let aboutusItem of aboutusData">
  <h4>{{ aboutusItem.sub_title }}</h4>
  <h2 class="title-inner1">{{aboutusItem.title }}</h2>
</div>

<!-- About section start -->
<section class="section-b-space beauty-about" *ngFor="let aboutusItem of aboutusData" style="background-image: url('assets/images/banner/4872987.jpg');">
  <div class="container">
    <div class="row">
      <div class="col-xl-5 col-lg-6 col-md-12 offset-xl-1 text-center">
        <img [src]="baseUrl+ aboutusItem.image" alt="" class="img-fluid">
      </div>
      <div class="col-xl-5 col-lg-6 col-md-12">
        <div class="about-section">
          <div>
            <!-- <h2>about us</h2> -->
            <div class="about-text">

              <div [innerHTML]="aboutusItem.description"></div>

            </div>
            <div class="service small-section pb-0">
              <div class="row">
                <div class="col-sm-3 service-block1">
                  <h4 style="color: red;">{{ Bus_operations }}</h4>
                  <h5>Buses in Operations</h5>
                </div>
                <div class="col-sm-2 service-block1">
                  <h4 style="color: red;">{{ number_of_staff }}</h4>
                  <h5>Number of Staff</h5>
                </div>
                <div class="col-sm-2 service-block1">
                  <h4 style="color: red;">{{ passengers_per_day }}</h4>
                  <h5>Passengers per day</h5>
                </div>
                <div class="col-sm-3 service-block1">
                  <h4 style="color: red;">{{ monthly_unique_visitors }}</h4>
                  <h5>Monthly unique visitors</h5>
                </div>
                <div class="col-sm-2 service-block1">
                  <h4 style="color: red;">R{{ price_per_ticket }}.00</h4>
                  <h5>Price per ticket</h5>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- About section  end -->

<section class="section-b-space beauty-about" style="background-image: url('assets/images/banner/4872987.jpg');">
  <div class="container">

    <h2 class="text-center">HARAMBEE SERVICES</h2>
    <div class="row" *ngFor="let harambeeservicesItem of harambeeservicesData">

      <div class="col-xl-5 col-lg-6 col-md-12">
        <div class="about-section">
          <div>

            <h5 style="color: #ed3237;">{{ harambeeservicesItem.title }}</h5>
            <div class="about-text">

              <div [innerHTML]="harambeeservicesItem.description"></div>

            </div>

          </div>
        </div>
      </div>

      <div class="col-xl-5 col-lg-6 col-md-12 offset-xl-1 text-center">
        <img [src]="baseUrl+harambeeservicesItem.image" alt="" class="img-fluid" style="box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);">
      </div>
    </div>

  </div>
</section>

<!-- blog section start-->
<section class="blog p-t-0">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="title1 section-t-space">
          <h4>Recent News</h4>
          <h2 class="title-inner1">from the KTVR</h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <app-blog [blogs]="blog"></app-blog>
      </div>
    </div>
  </div>
</section>
<!-- blog section End -->

<!--  logo section start-->

<section class="section-b-space">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="title1 section-t-space">
          <h4>Meet our</h4>
          <h2 class="title-inner1">stakeholders</h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <app-logo [logos]="logo"></app-logo>
      </div>
    </div>
  </div>
</section>

<!--  logo section End-->

<app-newsletter></app-newsletter>
<app-footer-one></app-footer-one>