import { Component, OnInit } from '@angular/core';
import { ProductSlider } from '../../../shared/data/slider';
import { Product } from '../../../shared/classes/product';
import { ProductService } from '../../../shared/services/product.service';
// 
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
// import { response } from 'express';
import { catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { baseUrl } from '../../../../config';

import { OwlOptions } from 'ngx-owl-carousel-o';
import { environment } from 'src/environments/environment';

@Component(

  {
  selector: 'app-fashion-one',
  templateUrl: './fashion-one.component.html',
  styleUrls: ['./fashion-one.component.scss']
}

)

export class FashionOneComponent implements OnInit {




  customOptions: any = {
    loop: true,
    margin: 10,
    autoplay: true,
    dots: false,
    responsiveClass: true,
    navText: ['Previous', 'Next'],
    responsive: {
      0: {
        items: 1
      },
      480: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: false // Enable navigation arrows
  };
  
  

  baseUrl: string;

  textDisplay:any;

  public products: Product[] = [];
  public productCollections: any[] = [];
  public active;

  carouselImageData:any
  aboutusData:any
  harambeeservicesData:any

  busOperationsData:any

  newsData:any

  sliders: any;
  conversionRates: any;

  Bus_operations:any
  number_of_staff:any
  passengers_per_day:any
  monthly_unique_visitors:any
  price_per_ticket:any
  private apibaseUrl = environment.apiUrl;
  constructor(public productService: ProductService,private http: HttpClient,) {

    this.productService.getProducts.subscribe(response => {
      this.products = response.filter(item => item.type == 'fashion');
      // Get Product Collection
      this.products.filter((item) => {
        item.collection.filter((collection) => {
          const index = this.productCollections.indexOf(collection);
          if (index === -1) this.productCollections.push(collection);
        })
      })
    });
  }

  public ProductSliderConfig: any = ProductSlider;

  // sliders:any

  // public sliders = [{
  //   title: 'welcome to fashion',
  //   subTitle: 'Men fashion',
  //   image: 'assets/images/slider/1.jpg'
  // }, {
  //   title: 'welcome to fashion',
  //   subTitle: 'Women fashion',
  //   image: 'assets/images/slider/2.jpg'
  // }]

  // Collection banner
  public collections = [{
    image: 'assets/images/collection/fashion/1.jpg',
    save: 'save 50%',
    title: 'men'
  }, {
    image: 'assets/images/collection/fashion/2.jpg',
    save: 'save 50%',
    title: 'women'
  }];

  // Blog
  
  public blog = [{
    image: 'assets/images/blog/1.jpg',
    date: '25 January 2018',
    title: 'Lorem ipsum dolor sit consectetur adipiscing elit,',
    by: 'John Dio'
  }, {
    image: 'assets/images/blog/2.jpg',
    date: '26 January 2018',
    title: 'Lorem ipsum dolor sit consectetur adipiscing elit,',
    by: 'John Dio'
  }, {
    image: 'assets/images/blog/3.jpg',
    date: '27 January 2018',
    title: 'Lorem ipsum dolor sit consectetur adipiscing elit,',
    by: 'John Dio'
  }, {
    image: 'assets/images/blog/4.jpg',
    date: '28 January 2018',
    title: 'Lorem ipsum dolor sit consectetur adipiscing elit,',
    by: 'John Dio'
  }];

  public logo :any
  // Logo



  ngOnInit(): void {

    // this.loadScript();

    console.log(" this.baseUrl = baseUrl;", this.baseUrl = baseUrl)

    this.fetchcarouselImageData()
    this.fetchaboutusData()
    this.fetchharambeeservicesData()
    this.fetchNewsData()

    this.fetchForeignExchangeData();
    this.fetchBusOperations();


  }





  fetchForeignExchangeData() {

    this.http.get<any>('https://v6.exchangerate-api.com/v6/412c937f76cb992142237950/latest/USD')
      .pipe(
        tap((response: any) => {

        }),
        catchError((error: HttpErrorResponse) => {

          console.log("fetchForeignExchangeData==>", error);
          console.error(error.error.message);
          return throwError(error);

        })
      )
      .subscribe((response: any) => {

        console.log("fetchForeignExchangeData", response)

        this.textDisplay =" Exchange Foreign  " 

        this.conversionRates = response.conversion_rates;

        console.log("in this.conversionRates",this.conversionRates)

      });
  }

  fetchBusOperations(){

    this.http.get<any>(`${this.apibaseUrl}/getBusOperationsData`)
    .pipe(
      tap((response: any) => {

      }),
      catchError((error: HttpErrorResponse) => {

        console.log("error==>", error);
        console.error(error.error.message);
        return throwError(error);

      })
    )
    .subscribe((response: any) => {

      console.log("response", response)

      this.busOperationsData = response.results

      console.log("busOperations", this.busOperationsData)

      this.Bus_operations = this.busOperationsData[0].Bus_operations
      this.number_of_staff = this.busOperationsData[0].number_of_staff
      this.passengers_per_day = this.busOperationsData[0].passengers_per_day
      this.monthly_unique_visitors = this.busOperationsData[0].monthly_unique_visitors
      this.price_per_ticket = this.busOperationsData[0].price_per_ticket
   


    });

  }

  fetchNewsData() {

    this.http.get<any>(`${this.apibaseUrl}/getnewsData`)
      .pipe(
        tap((response: any) => {

        }),
        catchError((error: HttpErrorResponse) => {

          console.log("error==>", error);
          console.error(error.error.message);
          return throwError(error);

        })
      )
      .subscribe((response: any) => {

        console.log("response", response)

        this.newsData = response.results

        console.log("news_table", this.newsData)

      });
  }


  fetchharambeeservicesData() {

    this.http.get<any>(`${this.apibaseUrl}/getharambeeservicesData`)
      .pipe(
        tap((response: any) => {

        }),
        catchError((error: HttpErrorResponse) => {

          console.log("error==>", error);
          console.error(error.error.message);
          return throwError(error);

        })
      )
      .subscribe((response: any) => {

        console.log("response", response)

        this.harambeeservicesData = response.results

        console.log("this.publicationData", this.harambeeservicesData)

      });
  }

  fetchaboutusData() {

    this.http.get<any>(`${this.apibaseUrl}/getaboutusData`)
      .pipe(
        tap((response: any) => {

        }),
        catchError((error: HttpErrorResponse) => {

          console.log("error==>", error);
          console.error(error.error.message);
          return throwError(error);

        })
      )
      .subscribe((response: any) => {

        console.log("response", response)

        this.aboutusData = response.results

        console.log("this.publicationData", this.aboutusData)

      });
  }


  fetchcarouselImageData() {

    this.http.get<any>(`${this.apibaseUrl}/getHomecarouselImage`)
      .pipe(
        tap((response: any) => {

        }),
        catchError((error: HttpErrorResponse) => {

          console.log("error==>", error);
          console.error(error.error.message);
          return throwError(error);

        })
      )
      .subscribe((response: any) => {

        console.log("response", response)

        this.sliders = response.results

        console.log("sliders", this.sliders)

      });
  }

  // Product Tab collection
  getCollectionProducts(collection) {
    return this.products.filter((item) => {
      if (item.collection.find(i => i === collection)) {
        return item
      }
    })
  }

  // loadScript() {
  //   const script = document.createElement('script');
  //   script.type = 'text/javascript';
  //   script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js';
  //   script.async = true;
  //   script.innerHTML = JSON.stringify({
  //     symbols: [
  //       { proName: "FOREXCOM:SPXUSD", title: "S&P 500 Index" },
  //       { proName: "FOREXCOM:NSXUSD", title: "US 100 Cash CFD" },
  //       { proName: "FX_IDC:EURUSD", title: "EUR to USD" },
  //       { proName: "BITSTAMP:BTCUSD", title: "Bitcoin" },
  //       { proName: "BITSTAMP:ETHUSD", title: "Ethereum" },
  //       { description: "ZAR", proName: "OANDA:USDZAR" },
  //       { description: "gold", proName: "OANDA:XAUUSD" }
  //     ],
  //     showSymbolLogo: true,
  //     isTransparent: false,
  //     displayMode: "adaptive",
  //     colorTheme: "dark",
  //     locale: "en"
  //   });
  //   document.querySelector('.tradingview-widget-container')?.appendChild(script);
  // }

}
