<owl-carousel-o [options]="BlogSliderConfig" class="no-arrow">
  <ng-container *ngFor="let blog of newsData">
    <ng-template carouselSlide>
      <div class="col-md-12">
        <a (click)="moreNews(blog)">
          <div class="classic-effect">
            <img [src]="baseUrl+blog.image" class="img-fluid img-shadow" alt="blog">
            <span></span>
          </div>
        </a>
        <div class="blog-details">
          <h4>{{blog.create_at | date:'EEEE, MMMM d, y'}}</h4>
          <a (click)="moreNews(blog)">
            <p>{{blog.title}}</p>
          </a>
          <hr class="style1">
          <h6> {{ 'by: KTVR' }} , {{ '2 Comment' }}</h6>
        </div>
      </div>
    </ng-template>
  </ng-container>
</owl-carousel-o>
